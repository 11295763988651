/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import FileFilter from "../components/FileFilter"
import Footer from "../components/footer"
import SEO from "../components/seo"
import Layout from "../components/layout"

const Files = props => {
  return (
    <Layout>
      <SEO title="Downloads" />
      <FileFilter files={props.data.allWpFile} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    allWpFile(sort: { fields: date, order: DESC }) {
      nodes {
        title
        date(formatString: "YYYY")
        fileQuery {
          fileDescription
          file {
            fileSize
            date(formatString: "YYYY")
            localFile {
              publicURL
            }
          }
        }
      }
    }
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    allWpEventPage {
      edges {
        node {
          title
          id
          cYAN {
            file {
              ... on WpFile {
                id
                title
                fileQuery {
                  fileDescription
                  file {
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Files
