/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"

import { BsFileEarmarkText } from "react-icons/bs"

const FileFilter = props => {
  const [search, setSearch] = useState("")
  const [title, setTitle] = useState("")
  const [dates, setDates] = useState("")
  const [year, setYear] = useState("")

  let filtered = props.files?.nodes?.filter(a => {
    if (search === "" && title !== "") {
      return a.title === title
    } else if (search !== "" && title === "") {
      return a.title.toLowerCase().indexOf(search.toLowerCase()) !== -1
    } else if (search !== "" && title !== "") {
      return (
        a.title.toLowerCase().indexOf(search.toLowerCase()) !== -1 &&
        a.title === title
      )
    }
  })

  let filtered1 = props.files.nodes.filter(a => {
    if (year === "" && dates === "") {
      return a.date === dates
    }
  })

  const changeYear = year => {
    setYear(year)
  }

  let dataTitle = props.files?.nodes?.map(e => e.title)

  let filterTitle = Array.from(new Set(dataTitle))

  let dataDates = props.files?.nodes?.map(e => e.date)

  let filter = Array.from(new Set(dataDates))

  const updateSearch = e => {
    setSearch(e.target.value)
  }

  return (
    <div sx={{ paddingTop: `170px` }} className="gap-xxl">
      <div id="files" className="grid-container">
        <h2>Downloads</h2>
        {/*<div sx={{ display: `flex` }}>
          <div sx={{ marginRight: `25px` }}>
            <input
              id="search"
              placeholder="Search for your file"
              type="text"
              sx={{
                width: `auto`,
                border: `1px solid rgba(0,0,0, 0.08)`,
                boxShadow: `none`,
              }}
              value={search}
              onChange={updateSearch}
            />
          </div>

         <select
            value={year}
            onChange={e => setYear(e.target.value)}
            sx={{
              color: `#000`,
              width: `225px`,
              [`&:focused`]: {
                color: `#000`,
              },
            }}
          >
            <option value="">All</option>
            {filter.map(y => {
              return (
                <option key={y} value={y}>
                  {y}
                </option>
              )
            })}
          </select>
        </div>
        <div>
          {year !== "" ? (
            <div>
              {filtered1.map(titles => {
                return (
                  <div>
                    <h4>{titles}</h4>
                  </div>
                )
              })}
            </div>
          ) : (
            <div>
              {props.files.nodes.map(e => {
                return (
                  <div key={e.id} className="">
                    <a
                      href={e.fileQuery.file.localFile.publicURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div
                        sx={{
                          display: `grid`,
                          gridTemplateColumns: `2fr 10fr`,
                          [`@media (max-width: 980px)`]: {
                            display: `grid`,
                            gridTemplateColumns: `3fr 9fr`,
                          },
                        }}
                        className="file"
                      >
                        <div>
                          <BsFileEarmarkText size={45} color={`#3a5d7f`} />
                        </div>
                        <div className="">
                          <h4 className="file-title">
                            {e.title}
                            <span className="file-size">
                              {" "}
                              {e.fileQuery.file.fileSize}
                            </span>
                          </h4>
                          <div
                            className="file-text"
                            dangerouslySetInnerHTML={{
                              __html: e.fileQuery.fileDescription,
                            }}
                          ></div>
                        </div>
                      </div>
                    </a>
                  </div>
                )
              })}
            </div>
          )}
        </div>*/}
        <div className="" sx={{ marginTop: `60px` }}>
          {search !== "" || title !== "" ? (
            <div
              className="gap-l"
              sx={{
                display: `grid`,
                gridTemplateColumns: ["12fr", "6fr 6fr", "repeat(3,1fr)"],
                gridTemplateRows: `repeat(auto)`,
                rowGap: `15px`,
              }}
            >
              {filtered?.map(e => {
                return (
                  <div key={e.id} className="">
                    <div className="">
                      <div key={e.id} className="">
                        <a
                          href={e?.fileQuery?.file?.localFile?.publicURL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div
                            className="file"
                            sx={{
                              display: `grid`,
                              gridTemplateColumns: `2fr 10fr`,
                              [`@media (max-width: 980px)`]: {
                                display: `grid`,
                                gridTemplateColumns: `3fr 9fr`,
                              },
                            }}
                          >
                            <div>
                              <BsFileEarmarkText size={45} color={`#3a5d7f`} />
                            </div>
                            <div className="large-9">
                              <h4 className="file-title">
                                {e.title}
                                {/*
                                <span className="file-size">
                                  {" "}
                                  {e.fileQuery.file.fileSize}
                                </span>
                                */}
                              </h4>
                              <div
                                className="file-text"
                                dangerouslySetInnerHTML={{
                                  __html: e?.fileQuery?.fileDescription,
                                }}
                              ></div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div
              className=""
              sx={{
                display: `grid`,
                gridTemplateColumns: ["12fr", "6fr 6fr", "repeat(3,1fr)"],
                gridTemplateRows: `repeat(auto)`,
                rowGap: `15px`,
              }}
            >
              {props.files?.nodes?.map(e => {
                return (
                  <div key={e.id} className="">
                    <a
                      href={e?.fileQuery?.file?.localFile?.publicURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div
                        sx={{
                          display: `grid`,
                          gridTemplateColumns: `2fr 10fr`,
                          [`@media (max-width: 980px)`]: {
                            display: `grid`,
                            gridTemplateColumns: `3fr 9fr`,
                          },
                        }}
                        className="file"
                      >
                        <div>
                          <BsFileEarmarkText size={45} color={`#3a5d7f`} />
                        </div>
                        <div className="">
                          <h4 className="file-title">
                            {e.title}
                            {/*
                            <span className="file-size">
                              {" "}
                              {e.fileQuery.file.fileSize}
                            </span>
                            */}
                          </h4>
                          <div
                            className="file-text"
                            dangerouslySetInnerHTML={{
                              __html: e?.fileQuery?.fileDescription,
                            }}
                          ></div>
                        </div>
                      </div>
                    </a>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FileFilter
